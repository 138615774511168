<template>
  <div class="van-main">
    <div v-if="$route.query.type !== 'all'" class="van-common-detail">
      <van-cell-group>
        <van-cell title="应收总额：" :value="utils.isEffectiveCommon(appForm.statementMoney)" />
        <van-cell title="待收金额：" :value="utils.isEffectiveCommon(appForm.amountToBeReceived)" />
        <van-cell title="收款方：" :value="utils.isEffectiveCommon(appForm.beneficiaryOrgName)" />
        <van-cell title="付款方：" :value="utils.isEffectiveCommon(appForm.payerOrgName)" />
        <van-cell title="本次实收金额：" :value="utils.moneyFormat(appForm.collectionAmount)" />
        <van-cell title="付款方账号：" :value="utils.isEffectiveCommon(appForm.paymentAccount)" />
        <van-cell title="收款日期：" :value="utils.isEffectiveCommon(appForm.collectionDate)" />
        <van-cell title="备注：" :value="utils.isEffectiveCommon(appForm.remarks)" />
        <file-list :file-list="appForm.fileDetailInfoVoList" title="付款凭证：" />
      </van-cell-group>
    </div>
    <div v-else>
      <div class="van-common-detail">
        <van-cell-group>
          <van-cell>
            <p class="lf">
              {{ utils.isEffectiveCommon(appForm.collectionStatus_Name) }}
            </p>
          </van-cell>
          <van-cell title="应付总额（元）：" :value="utils.moneyFormat(appForm.statementMoney) || 0" />
          <van-cell title="待收金额（元）：" :value="utils.moneyFormat(((((appForm.statementMoney || 0) * 1000) - amountPaid) / 1000).toFixed(2))" />
          <van-cell title="累计已收金额（元）：" :value="utils.moneyFormat((amountPaid / 1000).toFixed(2))" />
        </van-cell-group>
      </div>
      <div class="van-common-detail">
        <van-cell>
          <div class="goods-table">
            <table v-for="(item,idx) in appDownList" :key="item.logisticsCabinetNoId" class="inner-goods-table" border="1">
              <tr>
                <th colspan="2" class="header-th">
                  <span>{{ idx+1 }}</span>
                </th>
              </tr>
              <tr>
                <td>收款日期</td><td>{{ utils.dataString(item.collectionDate) }}</td>
              </tr>
              <tr>
                <td>收款方式</td><td>{{ item.collectionType ? '现汇' : '现汇' }}</td>
              </tr>
              <tr>
                <td>收款金额（元）</td><td>{{ utils.moneyFormat(item.collectionAmount) }}</td>
              </tr>
              <tr>
                <td>付款方</td><td>{{ appForm.beneficiaryOrgName }}</td>
              </tr>
              <tr>
                <td>付款方账号</td><td>{{ appForm.paymentAccount }}</td>
              </tr>
              <tr>
                <td>备注</td><td>{{ item.remarks }}</td>
              </tr>
              <tr>
                <td>附件</td>
                <td>
                  <file-list :file-list="item.fileDetailInfoVoList" title="" />
                  <!--                  <div v-for="(item, index) in item.fileDetailInfoVoList" :key="index">-->
                  <!--                    <a download :href="_.get(item, 'fileUrl')">{{ _.get(item, 'fileName', '-') }}</a>-->
                  <!--                  </div>-->
                </td>
              </tr>
              <tr>
                <td>状态</td><td>{{ utils.statusFormat(item.resultStatus, 'resultStatus') }}</td>
              </tr>
            </table>
          </div>
        </van-cell>
      </div>
    </div>
  </div>
</template>

<script>
import { List, PullRefresh, Cell, CellGroup, Search, Button, Field, Form, Col, Row, Tab, Tabs } from 'vant'
import fileList from '@/components/file-list'
export default {
  name: 'AttachmentList',
  components: {
    fileList,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [Form.name]: Form,
    [Col.name]: Col,
    [Row.name]: Row
  },
  props: {
    // 所属模块
    detailTables: {
      type: [Array],
      default () {
        return []
      }
    }
  },
  data () {
    return {
      appForm: {
        fileName: '',
        pageNum: 1,
        pageSize: 10,
        moduleName: ''
      },
      loading: false,
      finished: false,
      appDownList: [],
      amountPaid: 0
    }
  },
  created () {
    this.getInfoById()
  },
  methods: {
    // 获取详情
    getInfoById () {
      this.api.collection.getInfoById(this.$route.query.detailId).then(res => {
        this.appForm = res.data.value || {}
        this.getInfoByPaymentId()
      }).finally(() => {
      })
    },
    getInfoByPaymentId () {
      this.amountPaid = 0
      this.api.collection.getInfoByCollectionId(this.$route.query.collectionId).then(res => {
        this.appDownList = res.data.value || []
        console.log(this.appDownList, 5)
        this.appDownList.forEach(item => {
          item.collectionDate = (item.collectionDate || '').split('T')[0]
          if (item.resultStatus === 3) {
            this.amountPaid = this.amountPaid + ((item.collectionAmount || 0) * 1000)
          }
        })
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less">
</style>
